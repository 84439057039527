import React from "react";
import { StickyHeader } from "./Content";

type Dependency = { name: string; link: string };

type Project = {
  name: string;
  repo?: string;
  itchio?: string;
  npm?: string;
  link: string;
  img: string;
  description: string;
  dependencies: Dependency[];
};

const projects: Project[] = [
  {
    name: "sketch.heg.cool",
    link: "https://sketch.heg.cool",
    repo: "https://gitlab.com/heggroup/sketchbook",
    img: "sketch.png",
    description:
      "This is a place where I keep a collection of small graphics that I made.",
    dependencies: [
      { name: "React", link: "https://react.dev/" },
      { name: "tailwindcss", link: "https://tailwindcss.com/" },
    ],
  },
  {
    name: "Fishtank",
    link: "https://fishtank.heg.cool",
    repo: "https://gitlab.com/heggroup/fishtank",
    img: "fish.png",
    description:
      "Fishtank is a virtual fishtank I built in order to practice my pixel art.",
    dependencies: [
      { name: "PixiJS", link: "https://pixijs.com/" },
      {
        name: "My Electron Pixi Template",
        link: "https://gitlab.com/heggroup/electron-webpack-pixi-template",
      },
    ],
  },
  {
    name: "Linkup",
    link: "https://linkup.heg.cool",
    img: "viera.png",
    description:
      "Linkup is a daily football puzzle game where you have to connect two players by a chain of players who have played for the same club in the same year",
    dependencies: [
      { name: "ReactFlow", link: "https://reactflow.dev/" },
      { name: "GORM", link: "https://gorm.io/" },
      { name: "Postgres", link: "https://www.postgresql.org/" },
    ],
  },
  {
    name: "Blog",
    link: "https://gitlab.com/heggroup/neu-blog",
    repo: "https://gitlab.com/heggroup/neu-blog",
    img: "cool.png",
    description:
      "I made this blog. You can see the source code on my GitLab here.",
    dependencies: [
      { name: "React", link: "https://react.dev/" },
      { name: "tailwindcss", link: "https://tailwindcss.com/" },
    ],
  },
  {
    name: "DDR Buddy",
    link: "https://dance.heg.cool",
    repo: "https://gitlab.com/L-Heggerz/youtube-image-splicer",
    img: "dance.png",
    description:
      "DDR Buddy is an infantile interactive display I made for a Dance Dance Revolution themed birthday party.",
    dependencies: [],
  },
  {
    name: "Tix",
    link: "https://hegcool.itch.io/tix",
    repo: "https://gitlab.com/heggroup/tix",
    itchio: "https://hegcool.itch.io/tix",
    img: "tix-thumb.png",
    description:
      "Tix is a fiendishly diffuclt rhythm game I made for the Gamedev.js 2023 game jam. Navigate the factory whilst avoiding the drones and keeping your movements in time with the rhythm.",
    dependencies: [
      { name: "ReactPixi", link: "https://pixijs.io/pixi-react/" },
    ],
  },
  {
    name: "UseUndoableState",
    link: "https://www.npmjs.com/package/use-undoable-state",
    repo: "https://gitlab.com/heggroup/use-undoable-state",
    img: "use-undo-state.png",
    npm: "https://www.npmjs.com/package/use-undoable-state",
    description:
      "UseUndoableState is a little open source react hook I made that wraps some react state in some undo / redo behaviour.",
    dependencies: [{ name: "React", link: "https://react.dev/" }],
  },
  {
    name: "Electron Webpack Pixi Template",
    link: "http://electron-pixi.heg.cool/",
    repo: "https://gitlab.com/heggroup/electron-webpack-pixi-template",
    img: "pixi.png",
    description:
      "This is a template project I made that contains a minimalist setup for a game built using Typescript and PixiJS",
    dependencies: [
      { name: "PixiJS", link: "https://pixijs.com/" },
      { name: "Electron", link: "https://www.electronjs.org/" },
      { name: "webpack", link: "https://webpack.js.org/" },
    ],
  },
  {
    name: "HappySad",
    link: "https://happysad.heg.cool",
    img: "happysad.png",
    description:
      "HappySad is a simple JavaScript communication tool to aid neurodivergent developers in expressing their current emotional state.",
    dependencies: [
      { name: "smolpxl", link: "https://smolpxl.artificialworlds.net/" },
    ],
  },
  {
    name: "TurnTable",
    link: "https://gitlab.com/L-Heggerz/turntable",
    img: "turntable.png",
    repo: "https://gitlab.com/L-Heggerz/turntable",
    description:
      "TurnTable is the first webapp I ever made, it is an application for making multiplayer browser games. It uses WebSockets to communicate with browsers using JSON and then persists them in a datasource.",
    dependencies: [
      { name: "Redis", link: "https://redis.io" },
      { name: "Ktor", link: "https://ktor.io" },
    ],
  },
];

const DepLink = ({ d }: { d: Dependency }) => <a href={d.link}>{d.name}</a>;

const madeWith = (deps: Dependency[]) => {
  if (deps.length === 0) {
    return "no dependencies";
  } else if (deps.length === 1) {
    return <DepLink d={deps[0]} />;
  } else {
    return [
      ...deps
        .slice(0, deps.length - 1)
        .map((d) => [", ", <DepLink key={d.name} d={d} />])
        .flat()
        .slice(1),
      " and ",
      <DepLink key="last" d={deps[deps.length - 1]} />,
    ];
  }
};

const Links = ({
  project,
  className,
}: {
  project: Project;
  className: string;
}) => {
  return (
    <div className={`flex items-center ${className}`}>
      {project.npm !== undefined && (
        <a
          className="opacity-50 hover:opacity-100 w-[50px] flex place-content-center"
          href={project.npm}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="min-w-[25px] rounded"
            width={25}
            src="/pics/npm.svg"
          />
        </a>
      )}
      {project.itchio !== undefined && (
        <a
          className="opacity-50 hover:opacity-100 w-[50px] flex place-content-center"
          href={project.itchio}
          target="_blank"
          rel="noreferrer"
        >
          <img className="min-w-[30px]" width={30} src="/pics/itch.io.svg" />
        </a>
      )}
      {project.repo !== undefined && (
        <a
          className="opacity-50 hover:opacity-100 w-[50px] flex place-content-center"
          href={project.repo}
          target="_blank"
          rel="noreferrer"
        >
          <img className="min-w-[50px]" width={50} src="/pics/gitlab.svg" />
        </a>
      )}
    </div>
  );
};

export default function Projects() {
  return (
    <div className="pb-[60svh]">
      <StickyHeader header="> PROJECTS" />
      {projects.map((p, i) => [
        <StickyHeader key={`head-${i}`} header={p.name} link={p.link} />,
        <div key={`body-${i}`} className="flex gap-4 pb-16">
          <div className="flex flex-col items-center justify-between">
            <a href={p.link} target="_blank" rel="noreferrer">
              <img
                src={`/pics/${p.img}`}
                alt={p.img}
                width={150}
                height={150}
                className="min-w-[150px] border-[#ed1c24] border-2"
              />
            </a>
            <Links className="visible sm:invisible grow" project={p} />
          </div>
          <div className="flex flex-col gap-4">
            <p className="relative">{p.description}</p>
            <div className="flex justify-between grow">
              <p>Made using {madeWith(p.dependencies)}</p>
              <Links className="invisible sm:visible" project={p} />
            </div>
          </div>
        </div>,
      ])}
    </div>
  );
}
